body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.nothing  {
  vertical-align: top;  
  font-family: sf ui display, Verdana, sans-serif;
  list-style: none;
  color: black;
  text-decoration: none;
  font-size: 15px;
  text-align: left;  
  font-weight: bold;
}

.intro-container h1{
  font-family: 'Roboto', sans-serif;
    font-size: 1.5rem !important; /* Large and impactful heading */
    font-weight: 400 !important; /* Semi-bold font weight */
    margin-bottom: 20px !important; /* Space below the heading */
    color: #343a40 !important; /* Dark gray color for the heading */
}
