/* Global Styles */
body {
  font-family: "sf ui display", Verdana, sans-serif;
  font: 400 0.875em/1.75 Lato, Arial, Helvetica, sans-serif;
}

h1,
h2,
h3 {
  text-align: center;
  font-family: "Source Sans Pro", sans-serif;
}

h1 {
  font-weight: normal;
  color: pink;
}

h2 {
  font-weight: normal;
}

h3 {
  font-weight: bold;
}

a{
  color: #000 !important;
}

/* Table and Menu Styles */
table,
#TdMenu,
#Menu,
#TdCentral,
#Central,
#TdMoreInfo,
#MoreInfo {
  vertical-align: top;
}

table {
  height: 100%;
}

#TdMenu,
#TdMoreInfo {
  position: relative;
  top: 0px;
  width: 25%;
  height: 100%;
}

#Menu,
#Central,
#MoreInfo {
  position: relative;
  width: 100%;
  border: 1px solid grey;
}

#Central {
  text-align: top;
}

#TdCentral {
  width: 40%;
  /* Adjusted to remove duplicate */
}

/* Menu Links */

#menu   {
  font-size: 14px !important;  
}

#menu ul,
li,
a {
  vertical-align: top;  
  font-family: sf ui display, Verdana, sans-serif;
  list-style: none;
  color: black;
  text-decoration: none;
  font-size: 12px;
  text-align: right;
}


.normal {
  vertical-align: top;  
  font-family: sf ui display, Verdana, sans-serif;
  list-style: none;
  color: black;
  text-decoration: none;
  font-size: 12px;
  text-align: left;
  font-weight: normal;      
}

.menuFirstItem:hover {
  vertical-align: top;  
  font-family: sf ui display, Verdana, sans-serif;
  list-style: none;
  color: black;
  text-decoration: none;
  font-size: 16px;
  text-align: right;
  font-weight: bold;
}

#menu li:hover,
a:hover {
  text-decoration: none;
  font-weight: normal;
  color: #333;
}

/* Paragraph Styles */
p {
  margin: 10px;
  padding: 10px;
}

.card-header {
  background-color:  #333 !important;
  color:  #f8f9fa !important;  
}

.card-title {
  text-decoration: underline #FFF !important;
  text-align: center;
  
}

.card:hover{  
  border:1px #000 solid;  
}

/* General Link Styles */
a,
.link-style {
  color: #343a40;
  /* Dark grey for text */
  text-decoration:none ;
  /* Removes underline from links */
}

a:hover,
.link-style:hover {
  color: #23272b;
  /* Darker grey on hover */
  /*text-decoration: underline;*/
  /* Adds underline on hover for visual feedback */
}

/* Button Styles */
button {
  background-color: #343a40;
  /* Bootstrap dark grey */
  color: #f8f9fa;
  /* Bootstrap white grey */
  border: none;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
}

button:hover {
  background-color: #495057;
  /* Slightly lighter grey for hover */
}

/* Flex Styles for Layout */
.d-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Review Content and More Info Styles */
.review-content,
.more-info-card {
  flex: 1;
  /*text-align: center;*/
}

iframe {
  max-width: 100%;
}

.more-info-header {
  background-color:  #333 !important;
  color:  #f8f9fa !important;
}

.more-info-link {
  color: #17a2b8;
}

/* Ensures buttons and links within reviews and more info sections have consistent styling */
.btn-secondary,
.read-more-link {
  margin: 0 5px;
}

/* Accordion Header Customization */
.accordion-header {
  background-color:  #333 !important;
  color:  #f8f9fa !important;
}

.accordion-button {
  background-color:  #333 !important;
  color:  #f8f9fa !important;
  border-color: #333 !important;
  border: 0px;
}

.accordion-button:focus {
  background-color:  #333 !important;
  color:  #f8f9fa !important;
  border-color: #333 !important;
}

.list-group-item.list-group-item-action {
  text-decoration: underline #FFF !important;  
  font-weight: bold !important; /* Makes text bolder */
  font-family: "Source Sans Pro", sans-serif; /* Example font, adjust as needed */ 
}

.list-group-item a, .list-group-item a:hover {  
    vertical-align: top;  
    font-family: sf ui display, Verdana, sans-serif;
    list-style: none;
    color: black;
    text-decoration: none;
    font-size: 12px;
    text-align: right;    
    font-weight: normal; /* Makes text bolder */
}


.list-group-item:hover{
  background-color: rgb(238, 238, 238) !important;
}

.intro-container {
  text-align: center;
  color: #000;  
  padding: 20px;
  border-radius: 10px;
}

.intro-container h1 {
  display: block;
  font-weight: 700; /* Makes the title bold */
  font-size: 2rem; /* Adjust the size as needed */
  margin: auto 0; /* Vertically centers the logo in its column */
  margin: 0; /* Removes default margin */
  padding: 20px 0; /* Adds vertical padding and removes horizontal padding */
  text-align: start;
}

#logo{
  height:100px;
  /*display: block;*/
  max-width: 50%; /* Makes sure the image is responsive */
  height: auto; /* Maintains aspect ratio */
  margin: auto 0; /* Vertically centers the logo in its column */
  text-align: center;
}

.title {
  display: block;
  font-weight: 700; /* Makes the title bold */
  font-size: 2rem; /* Adjust the size as needed */
  margin: auto 0; /* Vertically centers the logo in its column */
  margin: 0; /* Removes default margin */
  padding: 20px 0; /* Adds vertical padding and removes horizontal padding */
  text-align: start;
}

/* Base styles for headers to ensure consistency */
h1, h2, h3 {
  font-weight: 700; /* Keeps headers bold */
  margin: 0; /* Removes default margins */
  padding: 10px 0; /* Provides some vertical spacing without horizontal padding */
  text-align: start; /* Aligns text to the start, consistent with .title */
}

/* Specific styles for each header level to create a visual hierarchy */
h1 {
  font-size: 2.5rem; /* Largest, as it's the most important */
  color: #1a1a1a; /* A strong color for high impact, adjust as needed */
}

h2 {
  font-size: 2rem; /* Slightly smaller than H1 */
  color: #333333; /* Slightly lighter color to differentiate from H1 */
}

h3 {
  font-size: 1.5rem; /* Smaller still, for less emphasis */
  color: #555555; /* Lighter than H2, ensuring a visual hierarchy */
}
/* Ensures the intro-container takes full width and centers its content */
.intro-container {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Styles for the text-and-button-container */
.text-and-button-container {
  padding: 20px; /* Add padding for aesthetic spacing */
}

/* Additional styles for the intro text for better visual appearance */
.intro-text {
  font-size: 1.25rem; /* Adjust font size as needed */
  margin-bottom: 1rem; /* Space between text and button */
}

/* Styles for the start search button */
.start-search-btn {
  font-size: 1rem;
  padding: 0.5rem 1rem; /* Button padding */
  background-color: #007bff; /* Bootstrap primary color */
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

/*.start-search-btn:hover {
  background-color: #0056b3; 
}*/

.custom-image-container {
  max-height: 60vh; /* Adjust as needed */
  overflow: hidden; /* This will prevent the image from overflowing its container */
}

.breadcrumb-item a{
  color: #1a1a1a;
}

.menuFirstItem {
  vertical-align: top;    
  list-style: none;
  color: black;
  text-decoration: none;
  font-size: 16px !important;
  text-align: left;  
  font-weight: bold !important;
  font-family: "Source Sans Pro", sans-serif;
  color: #000;
}